<template>
  <div class="bcategoryMind">
    <div class="bcategoryBody">
      <div class="bcategoryBodyTop">
        <div @click="showdialogClick" class="bcategoryBodyTopBttn">
          添加类别
        </div>
      </div>
      <div class="bcategoryBodyTable">
        <el-table
          :data="tableData"
          stripe
          style="width: 1300px; text-align: center"
        >
          <el-table-column type="expand" :show-overflow-tooltip="true">
            <template #default="props">
              <div v-if="props.row.specialityList">
                <div
                  v-for="item in props.row.specialityList"
                  :key="item.id"
                  class="hangClass"
                >
                  <div>{{ item.name }}</div>
                  <div
                    class="hangClassRight"
                    @click="deleteZiClick(props.row, item)"
                  >
                    删除
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品类别" width="680" />
          <el-table-column label="操作" width="560">
            <template #default="scope">
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                :text="true"
                @click="deleteSalesClick(scope.row)"
                >删除</el-button
              >
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                :text="true"
                @click="modifySalesClick(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                :text="true"
                @click="addCommodityClick(scope.row)"
                >添加商品</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 添加类别 -->
    <el-dialog
      v-model="dialogFormVisible"
      @closed="closedMenuClick"
      :title="title"
      width="400px"
    >
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item prop="name" label="类别名称：">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入类目名"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-if="showerji" @click="XiugaiClick"
            >修改</el-button
          >
          <el-button type="primary" v-else @click="SubmitClick">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加商品 -->
    <el-dialog
      v-model="dialogClassVisible"
      @closed="closedClick"
      title="添加商品"
      width="800px"
    >
      <el-form>
        <el-form-item label="添加商品：">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="item in checkAllList"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="SubmitClassClick">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //表格数据
      dialogFormVisible:false, //类别弹框
      title: '', //类别弹框标题
      showerji:false, //类别弹框修改按钮显示
      form: {        //类别弹框表单数据
        name:''
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },

      dialogClassVisible:false,   //添加商品弹框
      checkAllList: [],
      checkList: [],
    };
  },
  created() {},
  methods: {
    showdialogClick(){
      this.dialogFormVisible = true
      this.title = "新增商品类别";
      this.showerji = false;
    }
  },
};
</script>

<style>
.bcategoryMind {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Han Sans CN";
}
.bcategoryBody {
  width: 96%;
  height: 94%;
  overflow: auto;
  padding: 0px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.bcategoryBodyTop {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bcategoryBodyTopBttn {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: rgb(122, 191, 212);
}
.bcategoryBodyTopBttn:hover {
  cursor: pointer;
}
.bcategoryBodyTable {
  width: 100%;
  height: 600px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.el-table thead {
  --el-table-header-bg-color: #d7e3ed;
}
.bcategoryPagination {
  width: 100%;
  height: 60px;
}

.hangClass {
  width: 812px;
  height: 40px;
  padding-left: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hangClassRight {
  width: 100px;
  color: #409eff;
  text-align: center;
}
.hangClassRight:hover {
  cursor: pointer;
}
.menuImgUp {
  width: 80px;
  height: 80px;
  border: 1px dashed silver;
}
</style>